<template>
  <div id="dashboardIndexComponent">
    <div id="rev2_wrapper" class="rev_slider_wrapper fullwidthbanner-container">
      <div
        v-if="windowWidth >= 768"
        class="m-0 p-0 downscroll-button-style"
        data-easing="Power3.easeInOut"
        data-elementdelay="0.1"
        data-speed="1000"
        data-start="3200"
        style="z-index: 180 !important; position: absolute; bottom:-60px; left: 0px; width: 100vw; padding: 0px !important; margin: 0px !important;"
      >
        <div
          class="d-flex justify-content-center m-0 p-0 downscroll-main-style"
        >
          <a
            id="A_1"
            class="scrolldown mouse-down m-0 p-0"
            href="#nasil-kazanirim"
          >
            <img
              id="IMG_2"
              class="img-fluid m-0 p-0"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANAgMAAADUPqbNAAAADFBMVEX////////////////1pQ5zAAAAA3RSTlMAf4C/aSLHAAAAR0lEQVQIHQXBsRVAMBQAwOM9T5EhtLqswAY2YjJZQafNEAqNfHcGbKZF99gv46c8piZ/9leKNao+7jgo0ZDjRYqKPg44wcwPESEZJFQtW9IAAAAASUVORK5CYII="
            />
          </a>
        </div>
      </div>
      <div
        v-if="windowWidth < 768"
        class="m-0 p-0 downscroll-button-style"
        data-easing="Power3.easeInOut"
        data-elementdelay="0.1"
        data-speed="1000"
        data-start="3200"
        style="z-index: 180 !important; position: absolute; bottom:-60px; left: 0px; width: 100vw; padding: 0px !important; margin: 0px !important;"
      >
        <div
          class="d-flex justify-content-center m-0 p-0 downscroll-main-style2"
        >
          <a
            id="A_1"
            class="scrolldown mouse-down m-0 p-0"
            href="#nasil-kazanirim"
          >
            <img
              id="IMG_2"
              class="img-fluid m-0 p-0"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANAgMAAADUPqbNAAAADFBMVEX////////////////1pQ5zAAAAA3RSTlMAf4C/aSLHAAAAR0lEQVQIHQXBsRVAMBQAwOM9T5EhtLqswAY2YjJZQafNEAqNfHcGbKZF99gv46c8piZ/9leKNao+7jgo0ZDjRYqKPg44wcwPESEZJFQtW9IAAAAASUVORK5CYII="
            />
          </a>
        </div>
      </div>
      <div class="gap-for-narrow-width-device"></div>
      <div id="rev2" class="rev_slider fullwidthabanner">
        <ul>
          <!-- SLIDE  -->
          <li
            data-masterspeed="700"
            data-slotamount="5"
            data-transition="zoomout"
          >
            <div
              class=""
              style="background-color: rgb(10 155 140);
                    background-image: url(assets/images/npbg1.png);
                    background-position: center top;
                    width: 100%;
                    height: 100%;
                    opacity: 1;
                    visibility: inherit;
                    background-size: 500px auto;"
            ></div>
            <!-- LAYERS -->
            <div
              class="tp-caption lft skewtoleftshort rs-parallaxlevel-9"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-start="1400"
              data-x="-70"
              data-y="237"
              style="z-index: 4;"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/4U.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 8 -->
            <div
              class="tp-caption lfr randomrotateout tp-resizeme rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-splitin="none"
              data-splitout="none"
              data-start="2900"
              data-x="590"
              data-y="204"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/neat-design.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 9 -->
            <div
              class="tp-caption lfr randomrotateout tp-resizeme rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-splitin="none"
              data-splitout="none"
              data-start="3000"
              data-x="670"
              data-y="276"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/original-ideas.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 10 -->
            <div
              class="tp-caption lfr randomrotateout tp-resizeme rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-splitin="none"
              data-splitout="none"
              data-start="3100"
              data-x="720"
              data-y="348"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/clean-code.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 11 -->
            <div
              class="tp-caption lfr randomrotateout tp-resizeme rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-splitin="none"
              data-splitout="none"
              data-start="3200"
              data-x="720"
              data-y="420"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/latest-standards.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 12 -->
            <div
              class="tp-caption lfr randomrotateout tp-resizeme rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-splitin="none"
              data-splitout="none"
              data-start="3300"
              data-x="685"
              data-y="492"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/friendly-ux.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 13 -->
            <div
              class="tp-caption lfr randomrotateout tp-resizeme rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-splitin="none"
              data-splitout="none"
              data-start="3400"
              data-x="600"
              data-y="565"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/high-conversion.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 4 -->
            <div
              class="tp-caption lft skewtoleftshort rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-start="1700"
              data-x="392"
              data-y="310"
              style="z-index: 5;"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/slider-object4.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 5 -->
            <div
              class="tp-caption lft skewtoleftshort rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-start="2000"
              data-x="497"
              data-y="204"
              style="z-index: 6;"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/slider-object1.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 6 -->
            <div
              class="tp-caption lft skewtoleftshort rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-start="2300"
              data-x="602"
              data-y="310"
              style="z-index: 7;"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/slider-object2.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
            <!-- LAYER NR. 7 -->
            <div
              class="tp-caption lft skewtoleftshort rs-parallaxlevel-2"
              data-easing="Power3.easeInOut"
              data-elementdelay="0.1"
              data-speed="1000"
              data-start="2600"
              data-x="497"
              data-y="415"
              style="z-index: 8;"
            >
              <img
                alt=""
                data-lazyload="assets/images/demo-content/slider-object3.png"
                src="assets/images/demo-content/dummy.png"
              />
            </div>
          </li>
        </ul>
        <div class="tp-bannertimer"></div>
      </div>
    </div>
    <section id="nasil-kazanirim">
      <img :src="mediaBase + '/images/YAZI.png'" class="nap-text-position" />
      <video
        id="video"
        autoplay
        class="how-to-win-video-home"
        loop
        muted
        playsinline
      >
        <source
          id="mp4"
          :src="mediaBase + '/videos/video.mp4'"
          type="video/mp4"
        />
        <p>Your user agent does not support the HTML5 Video element.</p>
      </video>
    </section>
    <what-i-will-earn />
    <section class="download-section section mt-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-7">
            <div class="download-text">
              <h5 class="download-title">
                {{ $t("index.have_fun_learn_race_and") }}
              </h5>
              <h2 class="download-subtitle">{{ $t("index.win") }}</h2>
              <p class="download-title-describe">
                {{ $t("index.win_description") }}
              </p>
            </div>
            <div class="app-features">
              <div class="single-features">
                <div class="icon-box-outer">
                  <div class="icon-box">
                    <img
                      :src="mediaBase + '/images/l2.png'"
                      alt=""
                      width="55"
                    />
                  </div>
                </div>
                <h3 class="single-features-text">
                  {{ $t("index.have_fun_learn") }}
                </h3>
              </div>
              <div class="single-features">
                <div class="icon-box-outer">
                  <div class="icon-box">
                    <img
                      :src="mediaBase + '/images/l1.png'"
                      alt=""
                      width="55"
                    />
                  </div>
                </div>
                <h3 class="single-features-text">{{ $t("index.duel") }}</h3>
              </div>
              <div class="single-features">
                <div class="icon-box-outer">
                  <div class="icon-box">
                    <img
                      :src="mediaBase + '/images/l3.png'"
                      alt=""
                      width="55"
                    />
                  </div>
                </div>
                <h3 class="single-features-text">
                  {{ $t("index.Leadership") }}
                </h3>
              </div>
            </div>
            <div class="download-buttons">
              <div class="group-btn">
                <a
                  class="btn"
                  href="https://play.google.com/store/apps/details?id=com.napolyon.appv2&hl=tr&gl=US"
                  ><i class="fab fa-google-play"></i
                  >{{ $t("index.download_application") }} <br /><b>{{
                    $t("index.google_play")
                  }}</b></a
                >
                <a
                  class="btn"
                  href="https://apps.apple.com/tr/app/napolyon/id1035606237"
                  >{{ $t("index.download_application") }}<br /><b>{{
                    $t("index.app_store")
                  }}</b
                  ><i class="fab fa-apple"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="right-area">
              <img
                :src="mediaBase + '/images/download-bg-ellipse-01.png'"
                alt="#"
                class="ellipse-01"
              />
              <img
                :src="mediaBase + '/images/download-bg-ellipse-02.png'"
                alt="#"
                class="ellipse-02"
              />
              <img
                :src="mediaBase + '/images/download-bg-ellipse-03.png'"
                alt="#"
                class="ellipse-03"
              />
              <img
                :src="mediaBase + '/images/download-bg-ellipse-04.png'"
                alt="#"
                class="ellipse-04"
              />
              <img
                :src="mediaBase + '/images/download-smart-phone.png'"
                alt="#"
                class="smart-phone"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section bg2">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <ul class="media-list">
              <li
                class="media feature-right-bottom square-icon-hover dg-animation1"
                data-dg="fadeInDown"
              >
                <div class="media-body text-right-block">
                  <h4 class="media-heading uppercase">
                    {{ $t("index.tasks") }}
                  </h4>
                  <p>{{ $t("index.task_description") }}</p>
                </div>
                <div class="pull-right text-center">
                  <div class="square-icon-box square-icon-small">
                    <img
                      :src="mediaBase + '/images/icontablet1-2.png'"
                      alt=""
                      class="icons"
                      data-hover="assets/images/icontablet1.png"
                    />
                  </div>
                </div>
              </li>
              <li
                class="media feature-right square-icon-hover dg-animation1"
                data-dg="fadeInLeft"
              >
                <div class="media-body text-right-block">
                  <h4 class="media-heading uppercase">
                    {{ $t("index.nap_gestures") }}
                  </h4>
                  <p>{{ $t("index.nap_gestures_description") }}</p>
                </div>
                <div class="pull-right text-center">
                  <div class="square-icon-box square-icon-small">
                    <img
                      :data-hover="mediaBase + '/images/icontablet4-2.png'"
                      :src="mediaBase + '/images/icontablet4-1.png'"
                      alt=""
                      class="icons"
                    />
                  </div>
                </div>
              </li>
              <li
                class="media feature-right-top square-icon-hover dg-animation1"
                data-dg="fadeInUp"
              >
                <div class="media-body text-right-block">
                  <h4 class="media-heading uppercase">
                    {{ $t("index.Leadership") }}
                  </h4>
                  <p>{{ $t("index.Leadership_description") }}</p>
                </div>
                <div class="pull-right text-center">
                  <div class="square-icon-box square-icon-small">
                    <img
                      :src="mediaBase + '/images/icontablet3-1.png'"
                      alt=""
                      class="icons"
                      data-hover="assets/images/icontablet3-2.png"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-4 text-center">
            <img
              src="assets/images/demo-content/strongpoints.png"
              class="tablet-block"
            />
          </div>
          <div class="col-md-4">
            <ul class="media-list">
              <li
                class="media feature-left-bottom square-icon-hover dg-animation1"
                data-dg="fadeInDown"
              >
                <div class="pull-left text-center">
                  <div class="square-icon-box square-icon-small">
                    <img
                      :data-hover="mediaBase + '/images/icontablet2-2.png'"
                      :src="mediaBase + '/images/icontablet2-1.png'"
                      alt=""
                      class="icons"
                    />
                  </div>
                </div>
                <div class="media-body">
                  <h4 class="media-heading uppercase">
                    {{ $t("index.duel") }}
                  </h4>
                  <p>{{ $t("index.duel_description") }}</p>
                </div>
              </li>
              <li
                class="media feature-left square-icon-hover dg-animation1"
                data-dg="fadeInRight"
              >
                <div class="pull-left text-center">
                  <div class="square-icon-box square-icon-small">
                    <img
                      :data-hover="mediaBase + '/images/icontablet6-2.png'"
                      :src="mediaBase + '/images/icontablet6-1.png'"
                      alt=""
                      class="icons"
                    />
                  </div>
                </div>
                <div class="media-body">
                  <h4 class="media-heading uppercase">
                    {{ $t("index.have_fun_learn") }}
                  </h4>
                  <p>
                    {{ $t("index.have_fun_learn_description") }}<br /><br />
                  </p>
                </div>
              </li>
              <li
                class="media feature-left-top square-icon-hover dg-animation1"
                data-dg="fadeInUp"
              >
                <div class="pull-left text-center">
                  <div class="square-icon-box square-icon-small">
                    <img
                      :data-hover="mediaBase + '/images/icontablet5-2.png'"
                      :src="mediaBase + '/images/icontablet5-1.png'"
                      alt=""
                      class="icons"
                    />
                  </div>
                </div>
                <div class="media-body">
                  <h4 class="media-heading uppercase">
                    {{ $t("index.nap_transfers") }}
                  </h4>
                  <p>{{ $t("index.nap_transfers_description") }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div
        class="container-fluid np-bg-orange-image"
        style="background-image: url('media/images/bgorange.png')"
      >
        <div class="container">
          <div class="row align-items-center padding-top-60 padding-bottom-60">
            <div class="col-lg-6 col-md-6">
              <div class="row align-items-center">
                <div id="gears">
                  <div
                    id="gears-static"
                    style="background-image: url('media/images/gears.png')"
                  ></div>
                  <div id="gear-system-1">
                    <div id="shadow15" class="shadow"></div>
                    <div
                      id="gear15"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                    <div id="shadow14" class="shadow"></div>
                    <div
                      id="gear14"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                    <div id="shadow13" class="shadow"></div>
                    <div
                      id="gear13"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                  </div>
                  <div id="gear-system-2">
                    <div id="shadow10" class="shadow"></div>
                    <div
                      id="gear10"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                    <div id="shadow3" class="shadow"></div>
                    <div
                      id="gear3"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                  </div>
                  <div id="gear-system-3">
                    <div id="shadow9" class="shadow"></div>
                    <div
                      id="gear9"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                    <div id="shadow7" class="shadow"></div>
                    <div
                      id="gear7"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                  </div>
                  <div id="gear-system-4">
                    <div id="shadow6" class="shadow"></div>
                    <div
                      id="gear6"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                    <div
                      id="gear4"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                  </div>
                  <div id="gear-system-5">
                    <div id="shadow12" class="shadow"></div>
                    <div
                      id="gear12"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                    <div id="shadow11" class="shadow"></div>
                    <div
                      id="gear11"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                    <div id="shadow8" class="shadow"></div>
                    <div
                      id="gear8"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                  </div>
                  <div id="shadow1" class="shadow"></div>
                  <div
                    id="gear1"
                    style="background-image: url('media/images/gears.png')"
                  ></div>
                  <div id="gear-system-6">
                    <div id="shadow5" class="shadow"></div>
                    <div
                      id="gear5"
                      style="background-image: url('media/images/gears.png')"
                    ></div>
                    <div id="gear2"></div>
                  </div>
                  <div id="shadowweight" class="shadow"></div>
                  <div id="chain-circle"></div>
                  <div id="chain"></div>
                  <div
                    id="weight"
                    style="background-image: url('media/images/gears.png')"
                  ></div>
                </div>
              </div>
              <!--end row-->
            </div>
            <!--end col-->
            <div
              class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0 about-container"
            >
              <div class="ml-lg-4">
                <div class="section-title mb-2 pb-2">
                  <h4 class="title mb-4">{{ $t("index.about_us") }}</h4>
                  <p class="text-muted para-desc">
                    <span class="text-primary font-weight-bold">{{
                      $t("index.napolyon_com")
                    }}</span
                    >,{{ $t("index.napolyon_com_descrption") }}
                  </p>
                  <p class="text-muted para-desc mb-0">
                    {{ $t("index.napolyon_com_1_2") }}<br /><br />
                    {{ $t("index.napolyon_com_Do_rinsight") }} <br />
                    <br />
                    {{ $t("index.napolyon_com_content_us") }} <br />{{
                      $t("index.nap_points_accumulated_by_members")
                    }}
                  </p>
                </div>
                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h4 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >{{ $t("index.downloads") }}
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h4 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >{{ $t("index.offers") }}
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h4 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >{{ $t("index.sweepstakes") }}
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h4 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >{{ $t("index.privileges") }}
                  </li>
                </ul>
                <span class="text-muted para-desc">{{
                  $t(
                    "index.form_easily_accessible_turn_them_into_opportunities"
                  )
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="np-bg-green1">
      <div
        class="container-fluid background-napolyon1"
        style="background-image: url('media/images/npbg1.png'); background-color: rgb(10,155,140, 0.8);"
      >
        <div class="container">
          <div class="row justify-content-center padding-top-60">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4 text-white">
                  {{ $t("index.what_our_member_say") }}
                </h4>
                <p class=" para-desc mb-0 mx-auto text-white">
                  {{ $t("index.growing_exponentially_every_day") }}
                  <span class="font-weight-bold"
                    >{{ $t("index.Napolyon") }}
                  </span>
                  {{ $t("index.messages_from_her_family") }}
                </p>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
          <div class="row justify-content-center padding-bottom-60">
            <div class="col-lg-12 mt-4">
              <div id="customer-testi" class="owl-carousel owl-theme">
                <div class="media customer-testi m-2">
                  <img
                    :src="mediaBase + '/images/images/napolyon-avatar.jpg'"
                    alt=""
                    class="avatar avatar-small mr-3 rounded shadow"
                  />
                  <div
                    class="media-body content p-3 shadow rounded bg-white position-relative user-comment-box"
                  >
                    <ul class="list-unstyled mb-0">
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                    </ul>
                    <p class="text-muted mt-2">
                      Bilgi yarışmasından kazandığım Reeder marka tablet elime
                      ulaştı.Size &#231;ok teşekk&#252;r ediyorum
                    </p>
                    <h6 class="text-primary">
                      - Gülçin K. <small class="text-muted">Denizli</small>
                    </h6>
                  </div>
                </div>
                <div class="media customer-testi m-2">
                  <img
                    :src="mediaBase + '/images/images/napolyon-avatar.jpg'"
                    alt=""
                    class="avatar avatar-small mr-3 rounded shadow"
                  />
                  <div
                    class="media-body content p-3 shadow rounded bg-white position-relative user-comment-box"
                  >
                    <ul class="list-unstyled mb-0">
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star-half text-warning"></i>
                      </li>
                    </ul>
                    <p class="text-muted mt-2">
                      Kazandığıma dair mail geldiğinde &#231;ok heyecanlandım şu
                      anda sevin&#231;ten ellerimin titrediğini belirtmek
                      isterim &#231;ok sevin&#231;liyim.
                    </p>
                    <h6 class="text-primary">
                      - Burak Mehmet E. <small class="text-muted">Düzce</small>
                    </h6>
                  </div>
                </div>
                <div class="media customer-testi m-2">
                  <img
                    :src="mediaBase + '/images/images/napolyon-avatar.jpg'"
                    alt=""
                    class="avatar avatar-small mr-3 rounded shadow"
                  />
                  <div
                    class="media-body content p-3 shadow rounded bg-white position-relative user-comment-box"
                  >
                    <ul class="list-unstyled mb-0">
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                    </ul>
                    <p class="text-muted mt-2">
                      Napolyondan nakit &#246;d&#252;l&#252;m&#252; aldım. Hem
                      eğlenmek hemde nakit kazanmak hoş bir şey. İyi
                      g&#252;nler.
                    </p>
                    <h6 class="text-primary">
                      - Ercan K. <small class="text-muted">Kütahya</small>
                    </h6>
                  </div>
                </div>
                <div class="media customer-testi m-2">
                  <img
                    :src="mediaBase + '/images/images/napolyon-avatar.jpg'"
                    alt=""
                    class="avatar avatar-small mr-3 rounded shadow"
                  />
                  <div
                    class="media-body content p-3 shadow rounded bg-white position-relative user-comment-box"
                  >
                    <ul class="list-unstyled mb-0">
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                    </ul>
                    <p class="text-muted mt-2">
                      Napolyon aracılığı ile bizlere TOÇEV'e destek verme
                      fırsatı verdiğiniz için çok teşekkür
                      ederim.Çalışmalarınızda başarılar diliyorum.
                    </p>
                    <h6 class="text-primary">
                      - Arzu S. <small class="text-muted">İstanbul</small>
                    </h6>
                  </div>
                </div>
                <div class="media customer-testi m-2">
                  <img
                    :src="mediaBase + '/images/images/napolyon-avatar.jpg'"
                    alt=""
                    class="avatar avatar-small mr-3 rounded shadow"
                  />
                  <div
                    class="media-body content p-3 shadow rounded bg-white position-relative user-comment-box"
                  >
                    <ul class="list-unstyled mb-0">
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                      <li class="list-inline-item">
                        <i class="mdi mdi-star text-warning"></i>
                      </li>
                    </ul>
                    <p class="text-muted mt-2">
                      100 TL"mi aldım. Çok teşekkür ederim.
                    </p>
                    <h6 class="text-primary">
                      - Süleyman S. <small class="text-muted">Aydın</small>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DashboardLayout from "@/view/layouts/DashboardLayout";
import Footer from "@/view/layouts/Footer";
import WhatIWillEarn from "@/view/components/WhatIWillEarn";
import feather from "feather-icons";
import * as external from "@/core/mixin/external";
import { GET_ITEMS } from "@/core/services/store/rest.module";
import Swal from "sweetalert2";

export default {
  name: "dashboardIndexComponent",
  components: {
    DashboardLayout,
    "t-footer": Footer,
    WhatIWillEarn,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  beforeCreate() {
    $("#body").height("auto");
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    $("#body").height("auto");
    window.scrollTo(0, 0);
    feather.replace();
    external.head_script("/js/main.js", "main-js", "paroller-js");
    if (this.$route.name == "survey.redirect") {
      let self = this;
      let filters = {
        lid: this.$route.query.lid,
        mid: this.$route.query.mid,
      };
      self.$store
        .dispatch(GET_ITEMS, {
          url: "/linkTracker",
          filters: filters,
          returnType: "stateless",
        })
        .then((response) => {
          if (response.status) {
            if (response.data.status == 1) {
              window.location.href = response.data.content;
            } else {
              this.$router.push({ path: response.data.content });
            }
          } else {
            let error;
            try {
              error = response.data.response.data.content || "İşlem yapılırken hata oluştu. Lütfen sonra tekrar deneyiniz";
            } catch (e) {
              error =
                "İşlem yapılırken hata oluştu. Lütfen sonra tekrar deneyiniz";
            }
            let errorText =
              error ||
              "İşlem yapılırken hata oluştu. Lütfen daha sonra tekrar deneyiniz!";
            Swal.fire({
              title: "Hata oluştu!",
              html: errorText,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          }
        });
    }
    if (this.$route.name == "survey.redirect.custom") {
      let self = this;
      let filters = {
        Pid: this.$route.query.Pid,
        scode: this.$route.query.scode,
      };
      self.$store
        .dispatch(GET_ITEMS, {
          url: '/linkTracker/customRedirect',
          filters: filters,
          returnType: "stateless",
        })
          .then((response) => {
            if (response.status) {
              let url = response.data;
              window.location.href = url;
            } else {
              let error;
              try {
                error = response.data.response.data.content || "İşlem yapılırken hata oluştu. Lütfen sonra tekrar deneyiniz";
              } catch (e) {
                error =
                    "İşlem yapılırken hata oluştu. Lütfen sonra tekrar deneyiniz";
              }
              let errorText =
                  error ||
                  "İşlem yapılırken hata oluştu. Lütfen daha sonra tekrar deneyiniz!";
              Swal.fire({
                title: "Hata oluştu!",
                html: errorText,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            }
          });
    }

    if (this.$route.name == "survey.redirect.temp") {
      let mid = this.$route.query.mid;
      let ca = this.$route.query.ca;
      this.$router.push({ name: 'index.survey_result_2', query: { mid: mid, ca: ca} });
    }
    setTimeout(function() {
      $("#customer-testi").owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      });
    }, 1000);
    // if (localStorage.getItem('reload') !== null)  {
    //   if (localStorage.getItem('reload') == 'force') {
    //     localStorage.removeItem('reload');
    //   }
    // } else {
    //   localStorage.setItem('reload', 'force');
    //   location.reload();
    // }
    const resizeOnly = () => {
      this.updateWindowWidth();
    };
    $(document).ready(function() {
      $(window).resize(resizeOnly);
    });
  },
  destroyed() {
    external.del_script("/js/main.js");
  },
};
</script>

<style scoped>
#topnav .navigation-menu.nav-light > li.active > a {
  color: #fff !important;
}

.user-comment-box {
  min-height: 210px;
}

.nap-text-position {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575.98px) {
  .nap-text-position {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .how-to-win-video-home {
    margin-top: 10vw;
  }

  .right-area {
    display: none;
  }

  .feature-right-bottom::before,
  .feature-right-top::before,
  .feature-right::before,
  .feature-left::before,
  .feature-left-top::before,
  .feature-left-bottom::before {
    content: "";
    position: absolute;
    display: none;
  }

  #gears {
    display: none;
  }

  .skewtoleftshort .scrolldown {
    line-height: 20px;
    height: 20px;
    width: 20px;
    bottom: 20px;
  }

  #dashboardIndexComponent {
    margin-top: 75px !important;
  }

  .tp-parallax-container .lfr {
    margin-right: -10px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .nap-text-position {
    position: absolute;

    left: 0;
    right: 0;
    margin-top: 70px;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .how-to-win-video-home {
    margin-top: 12vw;
  }

  .right-area {
    display: none;
  }

  .feature-right-bottom::before,
  .feature-right-top::before,
  .feature-right::before,
  .feature-left::before,
  .feature-left-top::before,
  .feature-left-bottom::before {
    content: "";
    position: absolute;
    display: none;
  }

  #gears {
    display: none;
  }

  .skewtoleftshort .scrolldown {
    line-height: 20px;
    height: 20px;
    width: 20px;
    bottom: 20px;
  }

  #dashboardIndexComponent {
    margin-top: 75px !important;
  }

  .tp-parallax-container .lfr {
    margin-right: -10px;
  }
}

.owl-carousel .owl-item img {
  width: auto !important;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .nap-text-position {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .right-area {
    transform: translate(-25px, 0px) scale(0.9);
  }

  .ellipse-01 {
    display: none;
  }

  .ellipse-02 {
    display: none;
  }

  .ellipse-03 {
    display: none;
  }

  #gears {
    transform: scale(0.8) translate(-5vw, 0px);
  }

  .feature-right-bottom::before,
  .feature-right-top::before,
  .feature-right::before,
  .feature-left::before,
  .feature-left-top::before,
  .feature-left-bottom::before {
    content: "";
    position: absolute;
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .right-area {
    transform: translate(-25px, 0px) scale(0.8);
  }

  .ellipse-01 {
    display: none;
  }

  .ellipse-02 {
    display: none;
  }

  .ellipse-03 {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .nap-text-position {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .feature-right-bottom::before,
  .feature-right-top::before,
  .feature-right::before,
  .feature-left::before,
  .feature-left-top::before,
  .feature-left-bottom::before {
    content: "";
    position: absolute;
    display: none;
  }
}

@media (min-width: 1200px) {
  .nap-text-position {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.downscroll-main-style {
  width: 100vw;
}

.downscroll-main-style2 {
  transform: translate(-10px, 0px) scale(0.7) !important;
}
html {
  scroll-behavior: smooth;
}
</style>
